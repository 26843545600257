import {ContactLink, Copyright, FooterLayout, FooterNav, OutChainLink, Statement} from "./styles";
import {GLOBAL} from "src/config";


function Footer() {

    if (!GLOBAL.footer.enable) {
        return null;
    }

    return <FooterLayout>
        <FooterNav>
            <ContactLink to={`/aboutus`}>
                关于我们
            </ContactLink>
            <ContactLink to={`/contact`}>
                联系我们
            </ContactLink>
        </FooterNav>

        <Statement>
            <OutChainLink href={"//beian.miit.gov.cn/"}>
                {process.env.REACT_APP_ICP_BEIAN_NO}
            </OutChainLink>
            <Copyright>
                {process.env.REACT_APP_COPYRIGHT}
            </Copyright>
        </Statement>
    </FooterLayout>
}

export default Footer;
