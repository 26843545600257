import styled from "styled-components";

export const TemplateContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  
  //.ig-page {
    //padding-bottom: constant(safe-area-inset-bottom);
    //padding-bottom: env(safe-area-inset-bottom);
  //}
`
export const TemplateBody = styled.div`
  overflow: auto;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  
  //.template--image &{
  //  //padding-bottom: 56px;
  //}
`;
export const TemplateContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Toolbar = styled.div`
  //position: absolute;
  z-index: 100;
  bottom: 0;
  width: 100%;
  background: #fff;
  box-shadow: 0px -4px 10px 2px #00000008;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  //padding-bottom: 20px;
`;
export const ToolbarContent = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  flex-flow: row nowrap;
  padding: 6px 8px;
`;
export const ToolbarContentLeft = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;
export const ToolbarContentRight = styled.div`
  display: flex;
  margin-left: 10px;
  flex: 1;
`
export const ToolbarButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  padding: 0 14px;
  
  //.icon {
  //  margin-bottom: 4px;
  //  width: 24px;
  //  height: 24px;
  //}
  //.toolbar-btn-text {
  //  font-size: 12px;
  //  color: #666;
  //  line-height: 1.4;
  //}
`
export const ToolbarButtonText = styled.span`
  font-size: 16px;
  margin-left: 4px;
`

const BUTTON_RADIUS = 22;
// export const ToolbarPrimaryButton = styled(Button).attrs(props => ({
//   type: props.type || 'primary'
// }))<{
//   background?: string
// }>`
//   flex: 1;
//   //margin-left: 10px;
//   background: ${props => props.background ?? `linear-gradient(135deg,#fb3b67,#f31d41)`};
//   //background: linear-gradient(134deg,#ff587e,#ff264a);
//   //background: linear-gradient(134deg,#ff587e,#ff264a);
//   border: none;
//   border-radius: 22px;
//   font-weight: 500;
// `
// export const ToolbarButtonGroup = styled.div`
//   flex: 1;
//   //background: linear-gradient(135deg,#fb3b67,#f31d41);
//   border: none;
//   border-radius: 22px;
//   font-weight: 500;
//   display: flex;
//   flex-direction: row;
//
// `
