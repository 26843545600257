import React from "react";
import {SecuredBoundary as FlySecuredBoundary, AccessDeniedException} from "@firefly/fly-security";
import Fallback from "./Fallback";
import Indicator from "src/component/Indicator";


export interface SecuredBoundaryProps {
    loading?: React.ReactNode;
    description?: string;
    children: React.ReactNode;
}
function SecuredBoundary(props: SecuredBoundaryProps) {
    const {
        loading = <Indicator fullscreen />,
        description = "登录后才可以看到您的邀请函呦！",
        children
    } = props;
    function fallback(_error: AccessDeniedException) {
        return <Fallback
            description={description}
        />
    }
    return <FlySecuredBoundary
        loading={loading}
        fallback={(error) => {
            return fallback(error);
        }}
    >
        {children}
    </FlySecuredBoundary>
}

export default SecuredBoundary;