import {useNavigate} from "react-router";
import {navigateToMiniapp, relaunchMiniapp} from "@firefly/fly-bridge";
import {isMiniapp, omitUndefined, queryStringify} from "@firefly/fly-defter";
import {Documents} from "@firefly/fly-design-core";
import {InvitationDesign, InvitationPage, Order, OrderLineItem, Template} from "@firefly/fly-design-inv-data";
import {useJumpTokenCreateMutation} from "src/hook/passport";
import {useGenerateWechatWeappURLMutation} from "src/hook/wechat";
import {useCallback} from "react";
import useTracking from "../trace/useTracking";
import {useSecurity} from "../context/security";



//
// export function navigateToWeapp(target: string, {
//     replace
// }: NavigationOptions = {}) {
//     if (replace) {
//         return WeappSDK.redirectTo(target);
//     } else {
//         return WeappSDK.navigateTo(target);
//     }
// }
// export function navigateToByteapp(target: string, {
//     replace
// }: NavigationOptions = {}) {
//     Promise.resolve().then(() => {
//         if (replace) {
//             return ByteAppSDK.redirectTo(target);
//         } else {
//             return ByteAppSDK.navigateTo(target);
//         }
//     })
//     return Promise.resolve();
// }

function params2query(params: Record<string, any>) {
    return new URLSearchParams(omitUndefined(params)).toString()
}
// export function navigateToMiniapp(path: string, params: Record<string, any>, options: NavigationOptions = {}) {
//     const query = params2query(params);
//     const miniappUrl = `${path}${query ? (`?` + query) : ''}`
//     if (isWeapp()) {
//         return navigateToWeapp(miniappUrl, options)
//     }
//     if (isByteapp()) {
//         return navigateToByteapp(miniappUrl, options)
//     }
//     return Promise.reject('current agent not miniapp');
// }

export interface NavigationOptions {
    replace?: boolean
}

export type WeappEnv = 'develop' | 'trial' | 'release';
export interface WeappJump {
    path?: string;
    query?: string;
    env?: WeappEnv;
}
export function useNavigateToWeapp(env?: WeappEnv) {
    const {
        mutateAsync
    } = useGenerateWechatWeappURLMutation();
    return (jump: WeappJump) => {
        return mutateAsync({
            scheme: 'weixin',
            jump: {
                ...jump,
                env: env,
            }
        }).then((data) => {
            window.location.href = data.url;
        })
    }
}

export function useInvokeToWeapp(env?: WeappEnv) {
    const navigateTo = useNavigateToWeapp(env);

    const tracking = useTracking();
    return (jump: WeappJump) => {
        // const advert = Adverts.get();
        let query = jump.query;
        // if (advert) {
        //     const propagateAdvert = Adverts.encode(advert);
        //     query += ((query ? '&' : '') + `p-advert=${encodeURIComponent(propagateAdvert)}`)
        // }
        if (tracking) {
            query += ((query ? '&' : '') + `invokerGid=${encodeURIComponent(tracking.gid)}`)
        }
        return navigateTo({
            ...jump,
            query: query,
        })
    }
}
export function useNavigateToWeappJumpLogin(env?: WeappEnv) {
    // const navigateToWeapp = useNavigateToWeapp(env);

    const invokeToWeapp = useInvokeToWeapp(env);

    const {
        mutateAsync: createJumpToken
    } = useJumpTokenCreateMutation();

    const {
        requestAuthenticated,
    } = useSecurity();
    return function (uri?: string): Promise<void> {
        return requestAuthenticated().then(() => {
            return createJumpToken().then((data) => {
                const {
                    token
                } = data;
                return invokeToWeapp({
                    path: 'page/passport/jump-login',
                    query: new URLSearchParams(omitUndefined<any>({
                        token: token,
                        withBind: true,
                        return: uri
                    })).toString(),
                })
            })
        })

    }
}

export function wrapHomepageWithNavigateTarget(withNavigateTo: string) {
    const params = new URLSearchParams({
        active: 'template',
        withNavigateTo: withNavigateTo,
    }).toString();
    return `/page/homepage/homepage?${params.toString()}`
}
export function useNavigateToWeappTemplate() {
    const navigate = useNavigateToWeappJumpLogin();
    return (template: Pick<Template, 'id' | 'doctype'>) => {
        let type = template.doctype.toLowerCase();
        if (Documents.isPageDoctype(type)) {
            type = 'page';
        }
        const templateParams = new URLSearchParams(omitUndefined<any>({
            id: template.id,
        })).toString();
        const target = `/page/template/${type}-preview?${templateParams.toString()}`;
        return navigate(target)
        // return navigate(wrapHomepageWithNavigateTarget(target))
    }
}

export function useNavigateToWeappMe() {
    const navigate = useNavigateToWeappJumpLogin();
    return () => {
        return navigate(`/page/homepage/homepage?active=me`)
    }
}
export function useNavigateToWeappMeDesign() {
    const navigate = useNavigateToWeappJumpLogin();
    return () => {
        return navigate(`/page/homepage/homepage?active=design`)
    }
}
export function useNavigateToOrderLineItem() {
    // const navigate = useNavigate();
    const navigateToTemplate = useNavigateToTemplate();
    return (lineItem: OrderLineItem) => {
        switch (lineItem.type) {
            case "WORK_TEMPLATE": {
                navigateToTemplate({
                    id: lineItem.targetId
                })
                break;
            }
        }
    }
}
export function useNavigateToTemplateList() {

    const navigate = useNavigate();
    return ({
        filters = {}
    }: {
        filters?: Record<string, any>
    } = {}) => {
        const params = new URLSearchParams();
        Object.keys(filters).forEach((key) => {
            params.set(key, filters[key]);
        })
        navigate({
            pathname: `/homepage/templates`,
            search: params.toString(),
        })
    }
}

export function useNavigateToTemplate() {
    const navigate = useNavigate();
    return useCallback((template: Pick<Template, 'id'>, {
        replace = false
    }: {
        replace?: boolean
    } = {}) => {
        navigate(`/templates/${template.id}`, {
            replace: replace
        });
    }, [navigate])
}


export function useNavigateToTemplatePayment() {
    const navigate = useNavigate();

    return function({
        id,
        from
    }: Pick<Template, 'id'> & {
        from?: string
    }, {
        replace = false
    }: NavigationOptions = {}): Promise<void>  {
        if (isMiniapp()) {
            return navigateToMiniapp(`/page/template/template-payment`, {
                id: id,
            })
        } else {
            Promise.resolve().then(() => {
                navigate({
                    pathname: `/templates/${id}/payment`,
                    search: queryStringify(omitUndefined({
                        from: from
                    }))
                }, {
                    replace: replace
                });
            })
        }
        return Promise.resolve();
    }
}

export function useRelaunchHomepageWithToDesignDesigner() {
    return (design: Pick<InvitationDesign, 'id' | 'doctype'>, options: {
        from?: string
    }) => {
        return relaunchMiniapp('/page/homepage/homepage', {
            active: "design",
            withNavigateTo: `/page/design/designer?id=${design.id}&doctype=${design.doctype}`
        }).then()
    }

}

// export function useNavigateToLogin() {
//
//     const navigate = useNavigate();
//     return (options: NavigationOptions = {}) => {
//         if (isMiniapp()) {
//             return navigateToMiniapp(`/page/passport/login`, {
//
//             }, options)
//         } else {
//             navigate(`/login`, {
//                 replace: options.replace
//             })
//             return Promise.resolve();
//         }
//     }
// }
export function useNavigateToOrderPayment() {
    const navigate = useNavigate();

    return function (order: {
        id: any
    }, options: {
        from?: string
        replace?: boolean
    } = {}) {
        const {
            from,
            replace
        } = options;
        //enter.extra ? JSON.stringify(enter.extra) :
        const extraValue = "";
        const params: Record<string, any> = omitUndefined({
            orderId: order.id,
            from: from,
            extra: extraValue
        });

        if (isMiniapp()) {
            return navigateToMiniapp(`/page/payment/order-payment`, params, {
                replace
            })
        } else {
            navigate(`/order-payment?${params2query(params)}`, {
                replace: options.replace
            })
            return Promise.resolve();
        }
    }

}

export function useRelaunchToMeDesign() {

    return () => {

        window.location.href = '/homepage/me';
    }
}
export function useRelaunchToTemplates() {
    const navigate = useNavigate();
    return () => {
        if (isMiniapp()) {

            return relaunchMiniapp(`/page/homepage/homepage`, {
                active: 'template',
            })
        }
        navigate(`/homepage/templates`)
        return Promise.resolve();
    }
}
export function useRelaunchToHomepageMyDesign() {

    const navigate = useNavigate();
    return () => {


        if (isMiniapp()) {
            return relaunchMiniapp(`/page/homepage/homepage`, {
                active: 'design',
            })
        }
        //TODO 准备升级的
        navigate(`/homepage/me`)
        return Promise.resolve();
    }
}

export function navigateToURL(url: string): Promise<void> {
    window.location.href = url;
    return new Promise<void>((resolve) => {
        // resolve();
        setTimeout(() => {
            resolve();
        }, 3000)
    })
}



export function navigateToCustomerService(): Promise<void> {
    window.location.href = `https://work.weixin.qq.com/kfid/kfcd3327059a4b29b80`;
    return Promise.resolve();
}


export function useNavigateToContactDesigner() {
    const navigate = useNavigate();
    return () => {
        navigate(`/contact-designer`)
        return Promise.resolve();
    }
}


export function useNavigateToOrderComplete() {
    const navigate = useNavigate();
    return useCallback((order: Pick<Order, 'id'>) => {
        navigate(`/orders/${order.id}/complete`)
        return Promise.resolve();
    }, [navigate])
}


export function useNavigateToPageShare2() {
    const navigate = useNavigate();
    return (page: InvitationPage, send?: string) => {
        if (isMiniapp()) {
            return navigateToMiniapp(`/page/page/share2`, {
                id: page.id,
                send: send,
            }, {
            })
        } else {
            const querystring = queryStringify(omitUndefined({
                send
            }), "?");
            navigate(`/invitation/pages/${page!.id}/inspect${querystring}`);
        }
        return Promise.resolve();
    }
}