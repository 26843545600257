import {isBot, isFunction, isWechat} from "@firefly/fly-defter";
import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useLayoutEffect,
    useMemo,
    useSyncExternalStore
} from "react";
import useTracking from "src/trace/useTracking";
import {executeQuery, useViewerQuery} from "./useViewerQuery";
import {useAnonymousLogin} from "./useAnonymousLogin";
import {
    useSecurityClient,
    isAccessDeniedException,
    useGraphQLClient,
} from "@firefly/fly-security";
import {Userinfo} from "./typing";
import {QueryCache, QueryClientProvider, useQueryClient} from "@tanstack/react-query";
import {isMessageError} from "@firefly/fly-http";
import {useMemoizedFn} from "@firefly/fly-hook";
import {useWebAuthentication, WebAuthenticationVariables} from "./useWebAuthentication";
import {UID_SESSION_STORAGE_KEY} from "../../config";
import {Reporter} from "../../util/reporter";

export interface SecurityContextType {
    viewer: Userinfo | undefined;
    logout: (returnUri: string) => void;
    authenticate: (variables: WebAuthenticationVariables) => Promise<void>;
    requestAuthenticated: () => Promise<void>;
}
const Context = createContext<SecurityContextType>(null as any);

export function useSecurity() {
    return useContext(Context);
}
export interface SecurityContextProps {
    children?: React.ReactNode;
}
export function SecurityContextProvider(props: SecurityContextProps): React.ReactElement {
    const {
        children
    } = props;
    const tracking = useTracking();
    const graphqlClient = useGraphQLClient();
    const securityClient = useSecurityClient();
    const client = useSecurityClient();
    const authenticationManager = securityClient.authenticationManager;
    const {
        isLoading,
        isPending,
        isAuthenticated,
        principal
    } = useSyncExternalStore(
        useCallback((onStoreChange) => {
            return authenticationManager.subscribe(onStoreChange);
        }, [authenticationManager]),
        () => {
            return authenticationManager.getCurrentState()
        }
    )

    // const queryClient = useQueryClient();
    //
    // useEffect(() => {
    //
    // }, []);
    const isInitialAuthenticated = isAuthenticated && 'uid' in (principal as any);
    useLayoutEffect(() => {
        if (isInitialAuthenticated) {
            queryClient.setQueryData(['viewer'], principal);
        }
    }, [isInitialAuthenticated, principal]);
    const {
        viewer
    } = useViewerQuery({
        enabled: isAuthenticated && !isInitialAuthenticated
    });
    const {
        isPending: isAnonymousLoginPending,
        isAnonymousUserLoading,
        authenticate: anonymousLogin,
        disabledAutoAnonymousLogin,
        useAutoAnonymousLogin,
    } = useAnonymousLogin();
    const {
        authenticate,
    } = useWebAuthentication();



    const logout = useCallback((returnUrl: string) => {
        sessionStorage.removeItem(UID_SESSION_STORAGE_KEY)
        if (!returnUrl) {
            returnUrl = window.location.href;
        }
        window.location.href = `/passport/logout.html?return=${encodeURIComponent(returnUrl)}`;
    }, [securityClient])

    const requestAuthenticated = useCallback(() => {
        const manager = securityClient.authenticationManager;
        return manager.requestAuthenticated().catch(() => {
            return authenticate({});
        });
    }, [securityClient, authenticate]);
    const ContextValue = useMemo<SecurityContextType>(() => {
        return {
            viewer: viewer??undefined,
            authenticate: (variables: WebAuthenticationVariables) => {
                return authenticate(variables).then();
            },
            logout,
            requestAuthenticated: () => {
                return requestAuthenticated().then();
            }
        }
    }, [viewer, authenticate, logout, requestAuthenticated]);

    useEffect(() => {
        if (isPending) {
            return;
        }
        if (isAuthenticated) {
            disabledAutoAnonymousLogin();
            return;
        }
        // console.log(`isAnonymousUserLoading: `, isAnonymousUserLoading, "isAnonymousLoginPending", isAnonymousLoginPending);
        if (isAnonymousUserLoading || isAnonymousLoginPending) {
            return;
        }
        // console.log(`useAutoAnonymousLogin: `, useAutoAnonymousLogin);
        if (useAutoAnonymousLogin) {
            if (isBot() || isWechat()) {
                return;
            }
            anonymousLogin().then();
        }
    }, [isPending, isAuthenticated, disabledAutoAnonymousLogin, isAnonymousLoginPending, isAnonymousUserLoading, useAutoAnonymousLogin, anonymousLogin]);
    useEffect(() => {
        if (tracking && viewer?.userId) {
            Reporter.config({
                metadata: {
                    userid: viewer.userId
                }
            })
        }
    }, [tracking, viewer?.userId]);

    const queryClient = useQueryClient();
    useEffect(() => {
        const defaultOptions = queryClient.getDefaultOptions();
        function handleSecurityError(error: any) {
            if (isAccessDeniedException(error)) {
                securityClient.invalidate();
                return true;
            }
            return false;
        }

        queryClient.setDefaultOptions({
            ...defaultOptions,
            // queries:  queryClient.defaultQueryOptions({
            //     // onError: function(error) {
            //     //     if (handleSecurityError(error)) {
            //     //         return;
            //     //     }
            //     //     defaultOptions.queries!.onError!(error);
            //     // }
            // }),
            // queryCache: new QueryCache({
            //     onError: (error, query) => {
            //         if (isFunction(query.meta?.onError)) {
            //             if (query.meta?.onError(error, query) === false) {
            //                 return;
            //             }
            //         }
            //         onError(error);
            //     },
            // }),
            mutations: queryClient.defaultMutationOptions({
                onError: function(error, variables, context) {
                    if (handleSecurityError(error)) {
                        return;
                    }
                    defaultOptions.mutations!.onError!(error, variables, context);
                }
            })
        })
    }, [queryClient, authenticate])
    return <Context.Provider value={ContextValue}>
        <QueryClientProvider client={queryClient}>
            {children}
        </QueryClientProvider>
    </Context.Provider>
}

export function SecurityContext(props: SecurityContextProps) {
    const {
        children
    } = props;




    return <SecurityContextProvider>
        {children}
    </SecurityContextProvider>
}