import {useNavigate, useSearchParams} from "react-router-dom";
import React, {useMemo, useState} from "react";
import {error as reportError} from "src/util/reporter";
import {Button, Image} from "antd-mobile";
import NavBar from "src/page/component/NavBar";
import {PaymentMethod} from "@firefly/fly-payment";
import {HomeO} from "@react-vant/icons";
import SafeAreaPadding from "src/component/SafeArea/SafeAreaPadding";
import {
    Body,
    Footer, FooterContent,
    OrderItemCard,
    OrderItemCardBody,
    OrderItemCardHeader,
    OrderLineItem,
    OrderLineItemMetaAmount,
    OrderLineItemMetaCover,
    OrderLineItemMetaDetail,
    OrderLineItemMetaTitle,
    Page,
    PaymentButtonAmount,
} from "../styles";
import OrderEmpty from "../component/OrderEmpty";
import {formatLineItemCoverUrl} from "../util";
import PaymentMethodSelect from "../component/PaymentMethodSelect";
import useOrderPayment from "./useOrderPayment";
import HelpFloatingBall from "src/component/HelpFloatingBall";
import PaymentCheckLoadingOverlay from "../component/PaymentCheckLoadingOverlay";
import useConfigPaymentMethods from "src/hook/useConfigPaymentMethods";
import Indicator from "src/component/Indicator";
import PaymentConfirmDialog from "../component/PaymentConfirmDialog";

interface OrderPaymentProps {

}

function OrderPayment(props: OrderPaymentProps) {

    const [searchParams] = useSearchParams();
    const {
        orderId,
        redirect = "template",
        extra,
    } = useMemo(() => {
        const extraValue = searchParams.get("extra");
        let extra = null;
        try {
            const extraString = extraValue ? decodeURIComponent(extraValue) : null;
            extra = extraString ? JSON.parse(extraString) : null;
        } catch (e) {}
        return {
            orderId: searchParams.get("orderId"),
            redirect: searchParams.get("redirect")??"template",
            extra: extra,
        }
    }, [searchParams]);



    const paymentMethods = useConfigPaymentMethods();

    const [paymentMethod, _setPaymentMethod] = useState<PaymentMethod>(() => {
        return paymentMethods[0]
    });
    const [paying, setPaying] = useState<boolean>(false);


    const {
        isPending,
        setPending,
        order,
        isCheckFetching,
        check,
        execute,
    } = useOrderPayment(orderId!);
    function setPaymentMethod(v: PaymentMethod) {
        if (paying) {
            return;
        }
        _setPaymentMethod(v);
    }

    const navigate = useNavigate();

    function onBack() {
        if (redirect === 'template' || redirect === 'designer' || redirect === 'homepage') {
            navigate(-2)
        } else {
            navigate(-1);
        }
    }



    // const {
    //     execute
    // } = usePaymentExecution(order?.paymentOrderId!);
    function executePayment() {
        setPaying(true);
        const args = {};
        execute({
            id: order!.id,
            paymentOrderId: order!.paymentOrderId
        }, paymentMethod, args, {
        }).catch((reason) => {
            if (reason === 'cancel') {
                return;
            }
            reportError(reason);
        }).finally(() => {
            setPaying(false)
        })
    }

    if(!order) {
        return <OrderEmpty />
    }
    return <SafeAreaPadding>
        <Page>
            <NavBar
                title={"支付"}
                onBack={onBack}
                right={<HomeO fontSize={22} />}
                onRightClick={() => {
                    navigate("/")
                }}
            />
            {order ? <>
                <HelpFloatingBall />
                <Body>
                    <OrderItemCard>
                        <OrderItemCardHeader>
                            项目
                        </OrderItemCardHeader>
                        <OrderItemCardBody>
                            {order.lineItems?.map((lineItem, index) => {

                                const coverUrl = formatLineItemCoverUrl(lineItem);
                                return <OrderLineItem key={index}>
                                    <OrderLineItemMetaCover>
                                        {coverUrl ? <Image
                                            src={coverUrl}
                                            fit={"contain"}
                                        /> : null}
                                    </OrderLineItemMetaCover>
                                    <OrderLineItemMetaDetail>
                                        <OrderLineItemMetaTitle>
                                            {lineItem.title}
                                        </OrderLineItemMetaTitle>
                                        <OrderLineItemMetaAmount>
                                            ￥ {lineItem.price}
                                            {/*<OrderItemMetaOriginalAmount>*/}
                                            {/*    ￥ {lineItem.originalAmount}*/}
                                            {/*</OrderItemMetaOriginalAmount>*/}
                                        </OrderLineItemMetaAmount>
                                    </OrderLineItemMetaDetail>
                                </OrderLineItem>
                            })}

                        </OrderItemCardBody>
                    </OrderItemCard>
                    <OrderItemCard padding={false}>
                        <PaymentMethodSelect
                            selectedValue={paymentMethod}
                            onSelect={setPaymentMethod}
                            methods={paymentMethods}
                        />
                    </OrderItemCard>
                </Body>
                <Footer>
                    <FooterContent>
                        <Button
                            className={"fly-button-primary"}
                            loading={paying}
                            color={"primary"}
                            onClick={executePayment}
                            disabled={paying}
                            block
                        >
                            立即支付 <PaymentButtonAmount>￥{order.amount}</PaymentButtonAmount>
                        </Button>
                    </FooterContent>
                </Footer>
                <PaymentCheckLoadingOverlay
                    visible={!isPending && isCheckFetching}
                />
                <PaymentConfirmDialog
                    // message={"请确认支付是有已完成"}
                    // confirmButtonText={"已完成支付"}
                    // confirmButtonColor={"#f55"}
                    // cancelButtonText={"遇到问题，重新支付"}
                    // cancelButtonColor={"#777"}
                    // showCancelButton
                    visible={isPending}
                    onCancel={() => {
                        setPending(false);
                    }}
                    onOk={() => {
                        check().then();
                        setPending(false)
                    }}
                />
            </> : null}
        </Page>
    </SafeAreaPadding>
}

export default Indicator.withSuspense(OrderPayment);
