import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import gql from "fake-tag";
import {useInfiniteConnectionQuery} from "@firefly/fly-kda-mobile";
import {Doctype} from "@firefly/fly-design-core";
import {Template, TemplateType, ViewerTemplate} from "@firefly/fly-design-inv-data";
import {TemplateTypes} from "../config";
import {useGraphQLClient} from "@firefly/fly-security";

export function useAddStarTemplateMutation() {
    const graphqlClient = useGraphQLClient();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (variables: {
            starrableId: string
        }) => {
            return graphqlClient.mutate({
                mutation: gql`
                    mutation addStarTemplate($starrableId: ID!) {
                        addStarTemplate(input: {starrableId: $starrableId}) {
                            error {
                                code,
                                message
                            },
                            starrable {
                                id
                            }
                        }
                    }
                `,
                variables: {
                    starrableId: variables.starrableId
                }
            }).then((output) => {
                const addStarTemplate = output.addStarTemplate;
                if (addStarTemplate.error) {
                    throw addStarTemplate.error
                }
                return addStarTemplate.starrable
            })
        },
        onMutate: (variables) => {
            const optimistic = {
                viewerHasStarred: true,
            }
            queryClient.setQueryData(['template', variables.starrableId], (data: any) => {
                return {
                    ...data,
                    ...optimistic,
                }
            })
            return {
                optimistic
            }
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries({
                queryKey: ['me', 'extra'],
            });
        },
        onError: (error, variables, context) => {
            queryClient.defaultMutationOptions().onError?.(error, variables, context);
            queryClient.setQueryData(['template', variables.starrableId], (data: any) => {
                return {
                    ...data,
                    viewerHasStarred: false
                };
            })
        },
        onSettled: (data, error, variables, context) => {
            // queryClient.setQueryData(['template', variables.starrableId], (data: any) => {
            //   return {
            //     ...data,
            //     viewerHasStarred: true
            //   };
            // })
        }
    })
}




export function useViewerTemplateQuery(id: string, {
    enabled = true,
}: {
    enabled?: boolean;
    // onSuccess?: (data: ViewerTemplate) => void;
} = {
}) {
    const graphqlClient = useGraphQLClient();
    return useQuery<ViewerTemplate>({
        queryKey: ['template', id],
        queryFn: (context) => {
            return graphqlClient.query({
                query: gql`
                    query template($id: ID!) {
                        template(id: $id) {
                            id,
                            title,
                            type,
                            doctype,
                            preview {
                                width,
                                height,
                                url
                            }
                            cover {
                                width,
                                height,
                                url
                            },
                            metadata,
                            pricing {
                                amount,
                                originalAmount
                            },
                            document,
                            viewerHasOwned,
                            viewerHasPurchased,
                            viewerHasStarred
                            createdAt
                        }
                    }
                `,
                variables: {
                    id: id
                }
            }).then((output) => {
                return output.template
            })
        },
        enabled: enabled,
        // onSuccess: onSuccess,
    })
}


export function useTemplatesInfiniteQuery({
                                                      keyword,
                                                      type,
                                                      doctype,
                                                      tags,
                                                  }: {
    keyword?: string;
    type?: TemplateType;
    doctype?: Doctype;
    tags?: string[];
}) {

    const graphqlClient = useGraphQLClient();
    return useInfiniteConnectionQuery<Template>({
        initialPageParam: {},
        queryKey: [
            "template",
            {
                tags,
                type,
                doctype,
                keyword,
            }
        ],
        queryFn: ({pageParam}) => {
            return graphqlClient.query({
                query: gql`
                    query queryTemplates($first: Int, $after: String, $type: TemplateType, $keyword: String, $doctype: String, $metadata: Map, $tags: [String!]) {
                        templates (first: $first, after: $after, type: $type, doctype: $doctype, keyword: $keyword, tags: $tags, metadata: $metadata, sortBy: SCORE) {
                            nodes {
                                id,
                                title,
                                type,
                                cover {
                                    width,
                                    height,
                                    url
                                }
                                preview {
                                    width,
                                    height,
                                    url
                                },
                                metadata,
                                doctype,
                                attributes {
                                    replaceableImageCount
                                },
                                pricing {
                                    amount,
                                    originalAmount
                                },
                                createdAt
                            },
                            pageInfo {
                                hasPreviousPage,
                                hasNextPage,
                                startCursor,
                                endCursor,
                            }
                        }
                    }
                `,
                variables: {
                    first: 12,
                    type: "WORK",
                    doctype: doctype,
                    tags: tags,
                    keyword: keyword,
                    ...pageParam,
                }
            }).then((output) => {
                return output.templates
            })
        },
        // getNextPageParam: (lastPage) => {
        //     return lastPage.pageInfo.hasNextPage ? {
        //         after: lastPage.pageInfo.endCursor
        //     } : null
        // },
    })
}


export function useTemplatesQuery({
    type = TemplateTypes.WORK,
    tags,
    styles,
    keyword,
    first = 10,
}: {
    type?: TemplateType,
    tags?: string[],
    styles?: string[],
    keyword?: string,
    first?: number,
}) {
    const graphqlClient = useGraphQLClient();
    return useQuery({
        queryKey: ['template', {
            type,
            styles,
            keyword,
            first
        }],
        queryFn: (context) => {
            return graphqlClient.query({
                query: gql`
                    query queryTemplates($type: TemplateType, $styles: [String!], $tags: [String!], $keyword: String, $first: Int, $after: String){
                        templates (type: $type, styles: $styles, keyword: $keyword, tags: $tags, first: $first, after: $after) {
                            nodes {
                                id,
                                title,
                                type,
                                cover {
                                    width,
                                    height,
                                    url,
                                }
                                preview {
                                    width,
                                    height,
                                    url
                                }
                                metadata,
                                doctype,
                                attributes {
                                    replaceableImageCount
                                },
                                pricing {
                                    amount,
                                    originalAmount
                                },
                                createdAt
                            },
                            pageInfo {
                                hasPreviousPage,
                                hasNextPage,
                                startCursor,
                                endCursor
                            }
                        }
                    }
                `,
                variables: {
                    type: type,
                    styles: styles,
                    tags: tags,
                    keyword: keyword,
                    first: 10,
                }
            }).then((output) => {
                return output.templates;
            })
        }
    })
}