// import {NavBar} from "react-vant";
import {useNavigate} from "react-router-dom";
import Footer from "../../component/Footer";
import {Article, Page, PageBody} from "./styles";
import NavBar from "src/page/component/NavBar";


function ContactUs() {

    const navigate = useNavigate();
    return <Page>
        <NavBar
            style={{
                // '--height': '36px',
                // '--border-bottom': '1px #eee solid',
            }}
            onBack={() => {
                navigate(-1)
            }}
        >
            关于我们
        </NavBar>
        <PageBody>
            <Article>
                <h4>关于我们</h4>
                <p>
                    萤影设计隶属于莆田萤耀网络科技有限公司
                    专门提供以H5邀请函、长表单、长单页、长海报、图片、短视频的设计和制作服务为主的H5互动综合营销平台。
                    帮助更多人轻松完成设计，实现更多人的梦想与创意。
                </p>
                <h4>联系地址</h4>
                <p>
                    福建省莆田市涵江区涵庭东路9号莆田高新区科技孵化器大楼3区434号
                </p>
                <h4>联系电话</h4>
                <p>
                    15375717130
                </p>
            </Article>
        </PageBody>
        <Footer
        />
    </Page>
}


export default ContactUs;
